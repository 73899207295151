
const TimeHelper = {

  isDateLessThanTenMinutesOld(date) {
    const diffInMilliseconds = new Date() - date;
    const diffInMinutes = diffInMilliseconds / (1000 * 60);
    return diffInMinutes <= 10;
  },

  msToClockTime(ms) {
    var seconds = (ms / 1000).toFixed(0);
    var minutes = Math.floor(seconds / 60);
    var hours = "";

    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = (hours >= 10) ? hours : "0" + hours;
      minutes = minutes - (hours * 60);
      minutes = (minutes >= 10) ? minutes : "0" + minutes;
    }

    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : "0" + seconds;

    if (hours != "") {
      return hours + ":" + minutes + ":" + seconds;
    }

    return minutes + ":" + seconds;
  },

  msToNarrativeTime(ms) {
    var seconds = (ms / 1000).toFixed(0);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    let value;
    let units;

    const secondsChunk = (seconds) => {
      return `${seconds}` + 's';
    }
    
    const minutesChunk = (minutes) => {
      return `${minutes}` + 'm';
    }
    
    const hoursChunk = (hours) => {
      return `${hours}` + 'h';
    }
    
    const daysChunk = (days) => {
      return `${days}` + ' d';
    }

    if (days > 0) {
      let label = daysChunk(days);

      const remainingHours = hours - (days * 24);

      if (remainingHours > 1) {
        label = `${label}, ${hoursChunk(remainingHours)}`
      }

      return label;
    }

    if (hours > 0) {
      let label = hoursChunk(hours);

      const remainingMinutes = minutes - (hours * 60);

      if (remainingMinutes > 1) {
        label = `${label}, ${minutesChunk(remainingMinutes)}`
      }

      return label;
    }

    if (minutes > 0) {
      let label = minutesChunk(minutes);

      const remainingSeconds = seconds - (minutes * 60);

      if (remainingSeconds > 1) {
        label = `${label}, ${secondsChunk(remainingSeconds)}`
      }

      return label;
    }

    return secondsChunk(seconds);
  },

  msToRoundedTime(ms, decimalPlace=0, asObject=false) {
    var seconds = (ms / 1000).toFixed(decimalPlace);
    var minutes = (seconds / 60).toFixed(decimalPlace);
    var hours = (minutes / 60).toFixed(decimalPlace);
    var days = (hours / 24).toFixed(decimalPlace);
    var weeks = (days / 7).toFixed(decimalPlace);
    let value;
    let units;

    const sendResult = function(value, units) {
      if (asObject) {
        return {
          value: value,
          units: units,
        }
      }

      return value + units;
    }

    if (weeks >= 1) {
      const weeksLabel = (weeks == 1) ? 'week' : 'weeks';
      return sendResult(weeks, weeksLabel);
    }

    if (days >= 1) {
      const daysLabel = (days == 1) ? 'day' : 'days';
      return sendResult(days, daysLabel);
    }

    if (hours >= 1) {
      const hoursLabel = (hours == 1) ? 'hr' : 'hrs';
      return sendResult(hours, hoursLabel);
    }

    if (minutes >= 1) {
      const minutesLabel = (minutes == 1) ? 'min' : 'mins';
      return sendResult(minutes, minutesLabel);
    }

    const secondsLabel = seconds == 1 ? ' sec' : ' secs';
    return sendResult(seconds, secondsLabel);
  },

  wait(milliseconds) {
    const date = Date.now();
    let currentDate = null;

    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  },

  isoDateTimeStamp() {
    return new Date().toISOString();
  },

  isoToEpoch(isoVal) {
    return new Date(isoVal).getTime();
  },

  epochToIso(epochVal) {
    return new Date(epochVal).toISOString();
  },


};

export default TimeHelper;
