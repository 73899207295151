
// substrate and utils
import EventManager       from '@brainscape/event-manager';
import PropTypes          from 'prop-types';
import React              from 'react';
import Tracker            from '_utils/Tracker';
import UiHelper           from '_utils/UiHelper';
import {toClassStr}       from '_utils/UiHelper';

// models
import packConfidence     from '_models/packConfidence';
import packDuplicate      from '_models/packDuplicate';
import userLocalStore     from '_models/userLocalStore';
import userPack           from '_models/userPack';
import userPref           from '_models/userPref';

// sub-components
import OptionsMenuButton  from '_views/shared/OptionsMenuButton';


const PT = {      
  addClasses:                   PropTypes.string,
  currentPack:                  PropTypes.object,
  currentUser:                  PropTypes.object,
  iconType:                     PropTypes.string, 
  initialOptionsOpenState:      PropTypes.bool,
  isMobileViewportSize:         PropTypes.bool,
  tooltipContent:               PropTypes.string,
  tooltipPosition:              PropTypes.string,
};

const MENU_OPTIONS_HASH = {
  importCsv: {id: 'importCsv', tag: 'import-csv', label: 'Import CSV', onlyDeckEditor: true, onlyPro: false, notInAiCardListImportFeature: true},
  importFlashcards: {id: 'importFlashcards', tag: 'import-flashcards', label: 'Import/Paste Flashcards', onlyDeckEditor: true, onlyPro: false, notMobileViewport: true, inAiCardListImportFeature: true},
  exportCsv: {id: 'exportCsv', tag: 'export-csv', label: 'Export CSV', onlyPackAdmin: true, onlyIfHasDecks: true, onlyPro: false},
  duplicatePack: {id: 'duplicatePack', tag: 'duplicate-pack', label: 'Duplicate Class', paywall: 'duplicate_class', featuresList: 'list-6', onlyPro: true, onlyDuplicatable: true},
  resetStats: {id: 'resetStats', tag: 'reset-stats', label: 'Reset Class Stats', paywall: 'stats_reset_pack', featuresList: 'list-2', onlyStudyable: true, onlyIfHasDecks: true, onlyPro: true},
  removePack: {id: 'removePack', tag: 'remove-pack', label: 'Remove from your Classes', },
  separator2: {id: '---', label: null},
  makeClassPrivate: {id: 'makeClassPrivate', tag: 'make-class-private', label: 'Make Class Private', onlyPackAdmin: true, onlyIfPublic: true, onlyPro: true, paywall: 'privacy', featuresList: 'list-3'},
  makeClassPublic: {id: 'makeClassPublic', tag: 'make-class-public', label: 'Make Class Public', onlyPackAdmin: true, onlyIfPrivate: true, onlyPro: true, paywall: 'privacy'},
  previewPublicPage: {id: 'previewPublicPage', tag: 'preview-public-page', label: 'Preview Public Class Page'},
  separator3: {id: '---', label: null, onlyNonEditor: true, onlyPro: true},
  messageAuthor: {id: 'messageAuthor', tag: 'message-author', label: 'Message the Author', paywall: 'message_author', featuresList: 'list-6', onlyNonEditor: true, onlyPro: true},
  separator4: {id: '---', label: null},
  generateDummyData: {id: 'generateDummyData', tag: 'generate-dummy-data', label: 'Generate Dummy Data', onlyBscAdmin: true},
};


class PackOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      areDecksSortable: false,
      categoryHash: {},
      deckCards: [],
      deckName: '',
      dialogOpts: {},
      isProcessingOptionAction: false,
      menuOptions: [],
    };
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.updateUserOptions();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.currentPack.permission != prevProps.currentPack.permission) ||
      (this.props.currentPack.flags.isPrivate != prevProps.currentPack.flags.isPrivate)
    ) {
      this.updateUserOptions();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['pack-options-button', this.props.addClasses]);
    const openPosition = this.props.isMobileViewportSize ? 'bottomLeft' : 'bottomRight';

    return (
      <div className={classes}>
        <OptionsMenuButton
          addClasses="no-option-icons"
          menuOptions={this.state.menuOptions}
          iconType={this.props.iconType}
          initialOpenState={this.props.initialOptionsOpenState}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.currentUser.flags.isPro}
          hasLegacyStyles={false}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition={openPosition}
          shouldButtonTurn={false}
          tooltipContent={this.props.tooltipContent}
          tooltipPosition={this.props.tooltipPosition}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick(optionId) {
    let requiresPro = MENU_OPTIONS_HASH[optionId].onlyPro;

    if (requiresPro && !this.props.currentUser.flags.isPro) {
      let desiredAction = MENU_OPTIONS_HASH[optionId].label;
      let paywall = MENU_OPTIONS_HASH[optionId].paywall;
      let featuresList = MENU_OPTIONS_HASH[optionId].featuresList;
      
      this.showUpgradeModal(desiredAction, paywall, featuresList);

    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'importCsv':
        this.performImportCsvAction();
      break;
      case 'importFlashcards':
        this.performImportFlashcardsAction();
      break;
      case 'exportCsv':
        this.performExportCsvAction();
      break;
      case 'duplicatePack':
        this.performDuplicatePackAction();
      break;
      case 'makeClassPrivate':
        this.performMakeClassPrivateAction();
      break;
      case 'makeClassPublic':
        this.performMakeClassPublicAction();
      break;
      case 'removePack':
        this.performRemovePackAction();
      break;
      case 'deletePack':
        this.performRemovePackAction();
      break;
      case 'messageAuthor':
        this.performMessageAuthorAction();
      break;
      case 'resetStats':
        this.performResetStatsAction();
      break;
      case 'generateDummyData':
        this.performGenerateDummyDataAction();
      break;
      case 'previewPublicPage':
        this.performPreviewPublicPageAction();
      break;
    }
  }

  performDuplicatePackAction() {
    this.setState({
      isProcessingOptionAction: true,
    });

    packDuplicate.create(this.props.currentPack.packId).then(() => {
      this.triggerInfoModalOpen({
        title: 'Class Duplication Started',
        message: "We're creating a copy of this class and all of its decks. This will appear as a new class in your Classes. (This may take a few minutes. Feel free to refresh page to see if it's done.)",
        resolveButtonText: 'Got it',
        onCloseRequest: () => {
          this.setState({
            isProcessingOptionAction: false,
          });
        }
      });
    });
  }

  performExportCsvAction() {
    this.setState({
      isProcessingOptionAction: true,
    });

    UiHelper.openInNewTab(this.props.currentPack.paths.exportPath);

    this.triggerInfoModalOpen({
      title: 'Class Exported',
      message: "Check your browser's downloads folder for a CSV of your class",
      resolveButtonText: 'Got it',
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performGenerateDummyDataAction() {
    this.setState({
      isProcessingOptionAction: true
    });

    this.triggerConfirmModalOpen({
      actionText: 'reset current usage stats and generate new usage stats for this class. This action cannot be undone.',
      resolveButtonText: 'Yes, Generate Dummy Data',
      onResolution: () => {
        packConfidence.generateDummyData(this.props.currentPack.packId).then(() => {
          this.triggerInfoModalOpen({
            title: 'Dummy Data Generation Started',
            message: "Dummy Data is being generated for the " + this.props.currentPack.name + " class. It may take a little time, check back here soon.",
            resolveButtonText: 'Got it',
            onCloseRequest: () => {
              this.setState({
                isProcessingOptionAction: false,
              });
            }
          });
        });
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performImportCsvAction() {
    this.setState({
      isProcessingOptionAction: true,
    });

    this.triggerImportDeckModalOpen({
      pack: this.props.currentPack,
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performImportFlashcardsAction() {
    this.triggerParseCardListModalOpen();
  }

  performMakeClassPrivateAction() {
    this.triggerEditPackPrivacyModalOpen(true);
  }

  performMakeClassPublicAction() {
    this.triggerEditPackPrivacyModalOpen(false);
  }

  performMessageAuthorAction() {
    this.triggerMessageAuthorModalOpen();
  }

  performPreviewPublicPageAction() {
    const url = `${this.props.currentPack.paths.sharePreviewLink}?preview=true`;
    UiHelper.openInNewTab(url, '_blank');
  }

  performRemovePackAction = () => {
    this.setState({
      isProcessingOptionAction: true,
    });

    const actionText = `remove the ${this.props.currentPack.name} class from your library`;

    this.triggerConfirmModalOpen({
      actionText: actionText,
      resolveButtonText: 'Yes, remove class',
      onResolution: () => {
        userPack.remove(this.props.currentPack.packId);
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performResetStatsAction() {
    this.setState({
      isProcessingOptionAction: true
    });

    this.triggerConfirmModalOpen({
      actionText: 'reset Class Stats. This action will reset your confidences for each card in the class and cannot be undone',
      resolveButtonText: 'Yes, Reset Stats',
      onResolution: () => {
        packConfidence.reset(this.props.currentPack.packId);

        this.setState({
          isProcessingOptionAction: false,
        });
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerEditPackPrivacyModalOpen(newPrivacyState) {
    EventManager.emitEvent('edit-pack-privacy-modal:open', {
      newPrivacyState         : newPrivacyState,
      pack                    : this.props.currentPack,
    });
  }

  triggerEditPackPrivacyModalClose() {
    EventManager.emitEvent('edit-pack-privacy-modal:close', {});
  }

  triggerImportDeckModalOpen(viewProps) {
    EventManager.emitEvent('import-deck-modal:open', viewProps);
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerMessageAuthorModalOpen() {
    EventManager.emitEvent('message-author-modal:open', {
      authenticityToken:    this.props.authenticityToken,
      pack:                 this.props.currentPack,
    });
  }

  triggerParseCardListModalOpen = () => {   
    EventManager.emitEvent('parse-card-list-modal:open', {});
  }

  triggerUpgradeModalOpen(desiredAction, paywall, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
      featuresList:  featuresList,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  showUpgradeModal(desiredAction, paywall, featuresList) {
    if (!paywall) {
      if (desiredAction == 'Export CSV') { paywall = 'csv_export_pack'; }
    }
    Tracker.trackPaywallWarning(paywall);
    this.triggerUpgradeModalOpen(desiredAction, paywall, featuresList);
  }

  updateUserOptions() {
    const currentPack = this.props.currentPack;
    const currentUser = this.props.currentUser;

    let menuOptionsForUser = Object.keys(MENU_OPTIONS_HASH).reduce((accumulator, menuOption) => {
      let permitPerEditDeckRights = (MENU_OPTIONS_HASH[menuOption].onlyDeckEditor) ? currentPack.flags.areDecksEditable : true;
      let permitPerBscAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyBscAdmin) ? currentUser.flags.isBscAdmin : true;
      let permitPerPackAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyPackAdmin) ? currentPack.permission == 'admin' : true;
      let permitPerPackOrBscAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyPackOrBscAdmin) ? (currentPack.permission == 'admin' || currentUser.flags.isBscAdmin) : true;
      let permitPerDecksExistence = (MENU_OPTIONS_HASH[menuOption].onlyIfHasDecks) ? currentPack.stats.deckCount > 0 : true;
      let permitPerNonEditRights = (MENU_OPTIONS_HASH[menuOption].onlyNonEditor) ? !currentPack.flags.areDecksEditable : true;
      let permitPerDuplicateRights = (MENU_OPTIONS_HASH[menuOption].onlyDuplicatable) ? currentPack.flags.isDuplicatable : true;
      const permitPerPackIsPrivate = (MENU_OPTIONS_HASH[menuOption].onlyIfPrivate) ? currentPack.flags.isPrivate : true;
      const permitPerPackIsPublic = (MENU_OPTIONS_HASH[menuOption].onlyIfPublic) ? !currentPack.flags.isPrivate : true;
      const permitPerNotMobileViewport = (MENU_OPTIONS_HASH[menuOption].notMobileViewport) ? !this.props.isMobileViewportSize : true;
      const permitPerInAiCardListImportFeature = (MENU_OPTIONS_HASH[menuOption].inAiCardListImportFeature) ? currentUser.flags?.features?.ai_card_list_import : true;
      const permitPerNotInAiCardListImportFeature = (MENU_OPTIONS_HASH[menuOption].notInAiCardListImportFeature) ? !currentUser.flags?.features?.ai_card_list_import : true;

      if (permitPerEditDeckRights && permitPerBscAdminRights && permitPerPackAdminRights && permitPerPackOrBscAdminRights && permitPerDecksExistence && permitPerNonEditRights && permitPerDuplicateRights &&permitPerPackIsPrivate && permitPerPackIsPublic && permitPerNotMobileViewport && permitPerInAiCardListImportFeature && permitPerNotInAiCardListImportFeature) {
        accumulator.push(MENU_OPTIONS_HASH[menuOption]);
      }
      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

PackOptionsButton.propTypes = PT;

export default PackOptionsButton;
