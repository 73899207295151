
import { AiListParser }                         from '_services/AiListParser';
import { trackEvent }                           from '_services/AnalyticsService';
import EventManager                             from '@brainscape/event-manager';
import packDeckCard                             from '_models/packDeckCard';
import ParseCardListModal                       from '_views/modals/ai/ParseCardListModal';
import ParseCardListService                     from '_services/ParseCardListService';
import React, { useState, useEffect, useRef }   from 'react';


// TODO: Checking for the following error messages is a temporary solution. We should have a more robust way to check for these errors.
const AI_TOKEN_EXCEPTION_FREE_USER_MSG = 'You have exceeded your free AI token quota.';
const AI_TOKEN_EXCEPTION_PRO_USER_MSG = 'You have exceeded your daily AI token quota.';
const AI_PARSABLE_FILE_TYPES = ['csv', 'tsv', 'txt'];


const ParseCardListModalController = ({currentPackId, currentUser}) => {

  /*
  ==================================================
   INIT 
  ==================================================
  */

  const eventManager = new EventManager();

  
  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [cardRows, setCardRows] = useState([]);
  const [currentPanel, setCurrentPanel] = useState(''); // initial, paste, upload, review, loading, freeAiException, proAiException, unknownAiException
  const [errorMessage, setErrorMessage] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [fileName, setFileName] = useState('');
  const [hasInvalidInput, setHasInvalidInput] = useState(false);
  const [inputArrayBuffer, setInputArrayBuffer] = useState(null);
  const [inputFile, setInputFile] = useState(null);
  const [inputText, setInputText] = useState('');
  const [inputMethod, setInputMethod] = useState('');
  const [isApiResponseSuccessful, setIsApiResponseSuccessful] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);


  const isAiListParser = useRef(false);
  

  // on Mount
  useEffect(() => {
    /* SUBSCRIBE TO EVENTS */
    eventManager.addListener('parse-card-list-modal:open', handleOpenModal);
    eventManager.addListener('parse-card-list-modal:close', handleCloseModal);
    eventManager.addListener('parse-card-list-modal:paste-list-request', handlePasteListRequest);
    eventManager.addListener('parse-card-list-modal:upload-file-request', handleUploadFileRequest);
    
    eventManager.addListener('paste-list-panel:input-text-submit', handleInputTextSubmit);
    eventManager.addListener('paste-list-panel:go-back-request', handleGoBackRequest);

    eventManager.addListener('upload-file-panel:input-file-submit', handleInputFileSubmit);
    eventManager.addListener('upload-file-panel:go-back-request', handleGoBackRequest);
    eventManager.addListener('upload-file-panel:file-selected', handleFileSelected);
    eventManager.addListener('upload-file-panel:file-selection-error', handleFileSelectionError);

    eventManager.addListener('review-card-rows-panel:add-cards-request', handleAddCardsRequest);
    eventManager.addListener('review-card-rows-panel:fix-with-ai-request', handleFixWithAiRequest);
    eventManager.addListener('review-card-rows-panel:cancel-import-request', handleCancelImportRequest); 

    // on Unmount
    return () => {
      eventManager.disable();
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      trackEvent('importer', `view/${currentPanel}`);
    }
  }, [currentPanel, isModalOpen]);
  
  useEffect(() => {
    setErrorMessage('');
  }, [currentPanel]);
  

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleAddCardsRequest = (eventData) => {
    addCardsAndDeckToPack(eventData.cardRows);
  }

  const handleAcceptedDataParseError = (err) => {
    console.error(err);
    setErrorMessage(`Could not process the input data: ${err.message}.`);
    setHasInvalidInput(true);
    setIsProcessing(false);

    trackEvent('importer', 'choose_file', {error: true});    
  }

  const handleAiListParseError = (err) => {
    const errorMessage = err.message;

    if (errorMessage.indexOf(AI_TOKEN_EXCEPTION_FREE_USER_MSG) != -1) {
      setCurrentPanel('freeAiException');
      trackEvent('importer', 'ai/quota_exceeded', {isPro: false});
      return true;
    }

    if (errorMessage.indexOf(AI_TOKEN_EXCEPTION_PRO_USER_MSG) != -1) {
      setCurrentPanel('proAiException');
      trackEvent('importer', 'ai/quota_exceeded', {isPro: true});
      return true;
    }

    setCurrentPanel('unknownException');
  }

  const handleCancelImportRequest = () => {
    const initialImportPanel = inputMethod === 'file' ? 'upload' : 'paste';
    setCurrentPanel(initialImportPanel);

    trackEvent('importer', 'cancel/re-import');    
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    trackEvent('importer', 'cancel/exit');    
  };

  const handleFileSelected = () => {
    setHasInvalidInput(false);
    setErrorMessage('');
  }

  const handleFileSelectionError = (eventData) => {
    setHasInvalidInput(true);
    setErrorMessage(eventData.errorMessage);
  }

  const handleFixWithAiRequest = () => {
    ParseCardListService.setParser(AiListParser);
    isAiListParser.current = true;
    setCurrentPanel('loading');
    parseInputData({arrayBuffer: inputArrayBuffer, parserName: 'AiListParser'});
  }

  const handleGoBackRequest = () => {
    setCurrentPanel('initial');
  }

  const handleInputFileSubmit = (eventData) => {
    setIsProcessing(true);
    setInputFile(eventData.inputFile);
    isAiListParser.current = false;
    setFileExtension(''); // reset file extension
    setFileName(''); // reset file name

    ParseCardListService.acceptFile(eventData.inputFile).then((inputData) => {
      trackEvent('importer', 'choose_file', {
        name: inputData.fileName,
        size: inputData.arrayBuffer.byteLength,
      });

      parseInputData(inputData);
    }).catch((err) => {
      trackEvent('importer', 'choose_file', { error: true });
      handleAcceptedDataParseError(err);
    });
  };

  const handleInputTextSubmit = (eventData) => {
    setIsProcessing(true);
    setInputText(eventData.inputText);

    ParseCardListService.acceptText(eventData.inputText).then((inputData) => {
      parseInputData(inputData);
    }).catch((err) => {
      handleAcceptedDataParseError(err);
    });
  };

  const handleOpenModal = (eventData) => {
    setCurrentPanel('initial');
    isAiListParser.current = false;
    setIsProcessing(false);
    setHasInvalidInput(false);
    setIsModalOpen(true);
  };
  
  const handleParseInputDataError = (err) => {
    if (isAiListParser.current) {
      handleAiListParseError(err);
    }

    setErrorMessage(`Could not parse the input data: ${err.message}.`);
    setIsProcessing(false);
    setHasInvalidInput(true);
  }

  const handlePasteListRequest = () => {
    setInputMethod('text')
    setCurrentPanel('paste');
  }

  const handleUploadFileRequest = () => {
    setInputMethod('file')
    setCurrentPanel('upload');
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  const publishPackDeckCardsCreated = (packId, deckId, cardCount) => {
    EventManager.emitEvent('pack-deck-cards:created', {
      deckId: deckId,
      packId: packId,
      newCardCount: cardCount,
    })
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const addCardsAndDeckToPack = (cardRows) => {
    const deckName = getDeckName();
    const shouldSuppressModelPublish = true;
    setIsProcessing(true);

    packDeckCard.create(currentPackId, cardRows, deckName, shouldSuppressModelPublish)
      .then((rs) => {
        trackEvent('importer', 'add', {
          cardCount: cardRows.length,
          packId: currentPackId,
          deckId: rs.deckId,
        }); 

        publishPackDeckCardsCreated(currentPackId, rs.deckId, cardRows.length);
      })
      .catch(err => {
        console.error(err);
      });
  }

  const getDeckName = () => {
    if (inputMethod == 'file') {
      const fileName = ParseCardListService.getFileName();

      if (!fileName) {
        return 'Untitled Deck';
      }

      return fileName.split('.').slice(0, -1).join('.');
    }

    return 'Untitled Deck';
  }

  const getFileExtension = (fileName) => {
    if (!fileName) { return null; }

    return fileName.split('.').pop();
  }

  const isAiParsable = () => {
    if (inputMethod === 'text') {
      return true;
    }

    if (fileExtension && AI_PARSABLE_FILE_TYPES.includes(fileExtension)) {
      return true;
    }

    return false;
  }

  const parseInputData = (inputData) => {
    setInputArrayBuffer(inputData.arrayBuffer);
    setFileName(inputData.fileName);
    setFileExtension(getFileExtension(inputData.fileName));

    if (inputData.parserName === 'AiListParser') {
      trackEvent('importer', 'fix_with_ai');
    } else {
      trackEvent('importer', 'analyze', {name: inputData.fileName});
    }

    ParseCardListService.parseArrayBuffer().then(cardRows => {
      setHasInvalidInput(false); 
      setIsProcessing(false);
      setCardRows(cardRows);
      
      if (inputData.parserName === 'AiListParser') {
        setIsApiResponseSuccessful(true);

        const TIMEOUT_DURATION = 3000;

        setTimeout(() => {
          setCurrentPanel('review');
        }, TIMEOUT_DURATION);

      } else {
        setCurrentPanel('review');
      }
    }).catch(err => {
      handleParseInputDataError(err);
    });
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  
  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  if (isModalOpen) {
    return (
      <ParseCardListModal
        cardRows={cardRows}
        currentPanel={currentPanel}
        errorMessage={errorMessage}
        hasInvalidInput={hasInvalidInput}
        isAiListParser={isAiListParser.current}
        isAiParsable={isAiParsable()}
        isProcessing={isProcessing}
        isApiResponseSuccessful={isApiResponseSuccessful}
      />
    );
  }

  return null;
}

export default ParseCardListModalController;
