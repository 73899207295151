
import React, { useState, useEffect }   from 'react';

import { toClassStr }                   from '_utils/UiHelper';


const MAX_RANGE_VAL = 60;
const MIN_RANGE_VAL = 30;
const generateRandomMaxNum = () => Math.floor(Math.random() * (MAX_RANGE_VAL - MIN_RANGE_VAL + 1)) + MIN_RANGE_VAL;


const SimulatedProgressBar = ({ isApiResponseSuccessful }) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [percentage, setPercentage] = useState(0);
  const [stopAtPercentage, setStopAtPercentage] = useState(generateRandomMaxNum());

  useEffect(() => {
    const PROGRESS_UPDATE_INTERVAL = 2000;
  
    let interval;
  
    const startProgress = () => {
      if (isApiResponseSuccessful) {
        setPercentage(100);
        return;
      }
  
      interval = setInterval(() => {
        setPercentage((prev) => {
          if (isApiResponseSuccessful) {
            clearInterval(interval);
            return 100;
          }
          if (prev >= stopAtPercentage) {
            clearInterval(interval);
            return prev;
          }
          return Math.min(prev + Math.random() * 5, stopAtPercentage);
        });
      }, PROGRESS_UPDATE_INTERVAL);
    };
  
    startProgress();
  
    return () => clearInterval(interval);
  
  }, [isApiResponseSuccessful, stopAtPercentage]);
  
  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderSimulatedProgressBar = () => {
    const displayPercentage = Math.floor(Math.min(percentage, stopAtPercentage));

    return (
      <div className='simulated-progress-bar-container'>
        <div
          className='simulated-progress-bar-filler'
          style={{ width: `${percentage}%` }}
        >
          {Math.round(percentage)}%
        </div>
      </div>
    );
  }

  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['simulated-progress-bar']);

  return (
    <div className={classes}>
      {renderSimulatedProgressBar()}
  </div>
  );
}

export default SimulatedProgressBar;
