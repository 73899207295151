
import EventManager                     from '@brainscape/event-manager';
import PillButton                       from '_views/shared/PillButton';
import React, { useState, useEffect }   from 'react';
import SimpleTextButton                 from '_views/shared/SimpleTextButton';
import TextField                        from '_views/shared/TextField';

const HELP_ARTICLE_URL = 'https://brainscape.zendesk.com/hc/en-us';


const PasteListPanel = ({isProcessing, hasInvalidInput, errorMessage}) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [inputText, setInputText] = useState('');

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleTextChange = (e) => {
    setInputText(e.target.value);
  }

  const handleInputTextFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // check to make sure the text is not null and then process text import
    if (validateTextInput()) {
      triggerInputTextSubmit();
    }
  }

  const handleBackButtonClick = () => {
    triggerGoBackRequest();
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerGoBackRequest = () => {
    EventManager.emitEvent('paste-list-panel:go-back-request', {});
  }

  const triggerInputTextSubmit = () => {
    EventManager.emitEvent('paste-list-panel:input-text-submit', {
      inputText: inputText,
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const validateTextInput = () => { 
    return true; 
  };


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderErrorMessage = () => {
    if (!errorMessage) return null;
    return <div className='error-message'>{errorMessage}</div>;
  };


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  return (
    <div className="paste-list-panel">
      <div className='modal-title'>Paste list</div>

      <div className="text-field-and-caption">
        <TextField      
          addClasses='questions-and-answers-list-field'    
          onChange={handleTextChange}    
          placeholder='Paste or type a list of existing questions & answers or vocab words & definitions'
          type='textarea'
          value={inputText}
        />

        <div className='upload-info'>
          <div className="field-caption">
            <div className="left-field-caption"></div> 
            <div className="right-field-caption">
              <div className="right-caption">
                <i className="ion-ios-lightbulb"></i>
                <span className="caption-tip">Max. 500 rows at a time.</span>
                <a className="caption-link" href={HELP_ARTICLE_URL} target='_blank' rel="nofollow noopener">See more tips.</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {hasInvalidInput && renderErrorMessage()}
  
      <div className='modal-actions'>
        <SimpleTextButton
          addClasses='cancel-modal-text-button'
          label='Back'
          onClick={handleBackButtonClick}
        />
  
        <PillButton
          addClasses='resolve-modal-button'
          isDisabled={inputText.trim() === ''}
          isProcessing={isProcessing}
          label='Analyze'
          onClick={handleInputTextFormSubmit}
        />
      </div>
    </div>
  )
}

export default PasteListPanel;
