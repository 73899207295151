
import { apiGet } from '_core/Api2';
import UiHelper from '_utils/UiHelper';

const DelayedResultsHelper = {

  // returns a pending promise or an immediately resolved or rejected promise
  handleDelayedResults(rs) {
    if (rs.delayedResultId) {  
      return apiGet(`delayed_results/${rs.delayedResultId}`).then(function(newRs) {
        return DelayedResultsHelper.handleDelayedResults(newRs);
      });
    }
  
    if (rs.id && rs.startedEpoch) {
      if (rs.stoppedEpoch) {
        if (rs.error) {
          return Promise.reject(new Error(rs.error));
        }
  
        const delayedResult = JSON.parse(rs.result);
  
        if (delayedResult?.resultId) {
          return Promise.resolve(delayedResult);
        }
  
        return Promise.reject(new Error('Unexpected response'));
      }
  
      // Wait for 2 seconds before retrying
      return UiHelper.sleep(2000).then(function() {
        return apiGet(`delayed_results/${rs.id}`).then(function(newRs) {
          return DelayedResultsHelper.handleDelayedResults(newRs);
        });
      });
    }
  
    return Promise.reject(new Error('Unexpected response'));
  },
};

export default DelayedResultsHelper;
