
import { apiPost } from '_core/Api2';
import DelayedResultsHandler from '_utils/DelayedResultsHelper';
import { SupportLevel } from 'parse-card-list';


const MT = [
  'text/comma-separated-values',
  'text/csv',
  'text/plain',
  'text/tab-separated-values',
  'text/tsv',
];

const AiListParser = {
  mimeTypes: MT,
  name: 'Analyze Using AI 🤖',
  skipDefault: true,

  check: function(mimeType, body) {
    // We only want to return Maybe or No. We don't want to be the default
    // parser because this will cost GPT tokens.
    if (MT.includes(mimeType)) return SupportLevel.Maybe;

    return SupportLevel.No;
  },

  parse: function(_mimeType, body) {
    const params = {
      mimeType: 'text/plain',
      body: new TextDecoder().decode(body),
    };

    return apiPost('ai/cards/list_parse', params)
      .then(function(rs) {
        return DelayedResultsHandler.handleDelayedResults(rs).then(function(delayedResult) {
          if (delayedResult && delayedResult.cards) {
            return delayedResult.cards;
          } else {
            return Promise.reject(new Error('No cards found in the result.'));
          }
        });
      })
      .catch(err => {
        err.parser = 'AiListParser';
        console.log('AiListParser api error:', err);
        return Promise.reject(err);
      });
  },
};

export { AiListParser };
