
import EventManager       from '@brainscape/event-manager';
import PropTypes          from 'prop-types';
import React              from 'react';
import Spinner            from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses              : PropTypes.string,
  id                      : PropTypes.node,
  isDisabled              : PropTypes.bool,
  isProcessing            : PropTypes.bool,
  icon                    : PropTypes.bool,
  label                   : PropTypes.string,
  onBlur                  : PropTypes.func,
  onClick                 : PropTypes.func,
  onFocus                 : PropTypes.func,
  onKeyDown               : PropTypes.func,
  shouldDeferBlurOnClick  : PropTypes.bool, // this is used to fine tune click behavior
  spinnerColor            : PropTypes.string,
  tabIndex                : PropTypes.node,
  title                   : PropTypes.string,
  tooltipContent          : PropTypes.node,
  tooltipPosition         : PropTypes.node,
  type                    : PropTypes.string,
};


class PillButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false
    }

    this.pillButton = null;
    this.events = new EventManager();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const processingClass = (this.props.isProcessing) ? 'is-processing' : '';
    const disabledClass = (this.props.isDisabled) ? 'is-disabled' : '';
    const focusClass = (this.state.hasFocus) ? 'has-focus' : '';
    let classes = toClassStr(['pill-button', this.props.type, processingClass, disabledClass, focusClass, this.props.addClasses]);
    
    return (
      <div 
        className={classes}
        id={this.props.id}
        // icon={this.props.icon}
        label={this.props.label}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={(elem) => {this.pillButton = elem}}
        tabIndex={this.props.tabIndex}
        title={this.props.title}
        cyid={"PillButton"}
      >
        <span className="label">{this.props.label}</span>
        {this.renderSpinner()}
        {this.renderIcon()}
      </div>
    );
  }

  renderIcon () {
    if (!this.props.icon) {
      return null;
    }

    return (
      <div className='icon-button'>
        <i className="sparkles-icon" />
      </div>
    );
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner 
        color={this.props.spinnerColor || null}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClick = (e) => {
    e.stopPropagation();

    if (this.props.onClick && !this.props.isDisabled&& !this.props.isProcessing) {
      this.props.onClick();
    }
  }

  handleBlur = (e) => {
    this.setState({
      hasFocus: false,
    });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleFocus = (e) => {
    this.setState({
      hasFocus: true,
    });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  handleKeyDown = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  handleMouseDown = (e) => {
    if (this.props.shouldDeferBlurOnClick) {
      e.preventDefault();
    }
  }

  handleMouseEnter = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }
  }

  handleMouseLeave = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.props.tooltipContent,
      elem: this.pillButton,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  focus = () => {
    if (this.pillButton) { 
      this.pillButton.focus(); 
    }
  }
}

class LargeCtaButton extends React.Component {
  render() {
    const classes = toClassStr(['pill-button-large', this.props.addClasses]);

    return (
      <PillButton
        addClasses={classes}
        isProcessing={this.props.isProcessing}
        label={this.props.label}
        onClick={() => this.props.onClick()}
        title={this.props.title}
        type={this.props.type}
      />
    );
  }
}


PillButton.propTypes = PT;

export {
  PillButton as default, 
  LargeCtaButton, 
};
