
import EventManager                     from '@brainscape/event-manager';
import PillButton                       from '_views/shared/PillButton';
import React                            from 'react';
import SimpleTextButton                 from '_views/shared/SimpleTextButton';

import { toClassStr }                   from '_utils/UiHelper';


const ReviewCardRowsPanel = ({ cardRows, errorMessage, hasInvalidInput, isAiListParser, isAiParsable, isProcessing }) => {

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleAddCardsButtonClick = () => {
    triggerAddCardsRequest();
  }

  const handleFixWithAiButtonClick = () => {
    triggerFixWithAiRequest();
  }

  const handleCancelButtonClick = () => {
    triggerCancelRequest();
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerAddCardsRequest = () => {
    EventManager.emitEvent('review-card-rows-panel:add-cards-request', {
      cardRows: cardRows,
    });
  }

  const triggerCancelRequest = () => {
    EventManager.emitEvent('review-card-rows-panel:cancel-import-request', {});
  }

  const triggerFixWithAiRequest = () => {   
    EventManager.emitEvent('review-card-rows-panel:fix-with-ai-request', {});
  }

  
  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderCardRows = () => {
    const cardRowTrs = cardRows.map((card, index) => (
      <tr key={index} className={`${index % 2 === 0 ? 'even' : 'odd'} card`}>
        <td className='index'>{index + 1}</td>
        <td>{card.question}</td>
        <td>{card.answer}</td>
      </tr>
    ));

    return cardRowTrs;
  };

  const renderFixWithAiButton = () => {
    if (isAiListParser) {
      return null;
    }

    if (!isAiParsable) {
      return null;
    }

    return (
      <PillButton
        addClasses='resolve-modal-button fix-with-ai-button'
        isDisabled={isProcessing}
        isProcessing={false}
        icon={true}
        label='No, fix with AI'
        onClick={handleFixWithAiButtonClick}
      />
    )
  }


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['review-card-rows-panel']);

  return (
    <div className={classes}>
      <div className='modal-title'>Do these look right to you?</div>
      
      <div className='card-list-container'>
        <table border='1' cellPadding='10' className='card-list'>
          <thead>
            <tr>
              <th className='index-header'>#</th>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            {renderCardRows()}
          </tbody>
        </table>
      </div>

      <div className='modal-actions'>

        <SimpleTextButton
          addClasses='cancel-modal-text-button'
          label='Cancel'
          onClick={handleCancelButtonClick}
        />

        {renderFixWithAiButton()}

        <PillButton
          addClasses='resolve-modal-button pill-button-emphasized'
          isDisabled={false}
          isProcessing={isProcessing}
          label={`Yes, add ${cardRows.length} cards`}
          onClick={handleAddCardsButtonClick}
        />
      </div>
    </div>
  )
}

export default ReviewCardRowsPanel;
